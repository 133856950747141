.searchBarIn {
  	animation: fade-in .3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.searchBarOut {
    	animation: fade-out .3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.resultsCount{
  font-size: 10px;
  color: #253733;
}

.highlight{
  background-color:#FFF6DF;
}

.listText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.exitingIcon {
  position: absolute;
  top: calc(75% - 22px);
  right: 9px;
  margin-left: auto;
  margin-right: auto;
}

.indent {
  text-indent: 20px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
