.workorder-container {
  .p-datatable {
    font-size: 14px;
  }

  .p-row-toggler {
    width: 30px !important;
  }
}

.p-column-filter-overlay {
  z-index: 9999 !important;
}

.p-dropdown-panel {
  z-index: 9999 !important;
}

.p-multiselect-panel {
  z-index: 9999 !important;
}

.p-filter-column {
  padding: 14px !important;
  .p-inputtext {
    padding: 14px !important;
    font-size: 14px !important;
    line-height: 17px !important;
  }
}

.p-datatable .p-paginator.p-paginator-bottom {
  button.p-button.p-disabled {
    background-color: unset !important;
  }
}

.container-property-list {
  .p-datatable table {
    font-family: 'Montserrat';
    width: 100%;
  }

  .p-datatable .p-datatable-thead > tr > th {
    background-color: transparent;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.7em;
  }
}


.container-property-list.no-transparent {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: #F7F8FC;
  }

  .p-datatable .p-datatable-tfoot > tr > td {
    background-color: #F7F8FC;
  }
}

.p-datatable.layout-auto table {
  table-layout: auto;
}

.p-column-filter-overlay {
  z-index: 9999 !important;
}

.p-datepicker {
  z-index: 9999 !important;
}

.p-sortable-column-icon.pi-sort-alt {
  visibility: hidden !important;
}

.p-datatable-row-expansion .p-datatable .p-datatable-thead > tr > th {
  background-color: #EDEEF2 !important;
  &:hover {
    background-color: darken(#EDEEF2, 15%) !important;
  }
}

.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
  top: 8px;
  left: 2px;
}

.p-datatable-row-expansion .p-datatable-tbody > tr:last-child > td {
  border: none !important;
}

.p-datatable-thead > tr > th.column-center > .p-column-header-content {
  justify-content: center;
}

.p-datatable-tfoot > tr > td.column-money {
  text-align: right;
  font-weight: bold;
  max-width: 200px;
}

.p-datatable-thead > tr > th.column-money {
  justify-content: flex-end;
  max-width: 200px;
}

.p-datatable-thead > tr > th.column-money > .p-column-header-content {
  flex-direction: row-reverse;
}

.p-datatable-tbody > tr > td.column-money {
  justify-content: flex-end;
  max-width: 200px;
}

.p-datatable-scrollable.p-datatable-grouped-footer .p-datatable-tfoot>tr {
  display: flex;
}
