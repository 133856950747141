.MuiFormControl-root.Input .MuiInputBase-root {
  border-radius: 0;
}

.MuiFormControl-root.Input-dark .MuiInputBase-root {
  border-radius: 0;

  input {
    background-color: #222;
    color: white;
  }

  input::placeholder {
    color: #777;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #222 inset !important;
  box-shadow: 0 0 0 30px #222 inset !important;
  -webkit-text-fill-color: white !important;
  transition: background-color 5000s ease-in-out 0s
}

.Input-field {
  background-color: #f7f8fc;
  border-color: #eaecf6;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.4375em;
  height: 56px;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 0;
  padding: 16.5px 14px;

  &:focus {
    background-color: #f7f8fc;
    box-shadow: none;
    border-color: #0ab7b7;
  }

  &.is-invalid:focus {
    box-shadow: none;
  }

  &-white {
    background-color: white;
  }
}

.Input-field:-webkit-autofill,
.Input-field:-webkit-autofill:hover,
.Input-field:-webkit-autofill:focus,
.Input-field:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f7f8fc inset !important;
  box-shadow: 0 0 0 30px #f7f8fc inset !important;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.87) !important;
}
