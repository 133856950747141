$green: #86BB71;
$blue: #94C2ED;
$orange: #E38968;
$gray: #92959E;

.chat {
  width: 100%;
  float:left;
  background: #F2F5F8;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 100%;

  color: #434651;


  .chatHistory {
    position: relative;
    padding: 30px 30px 20px;
    border-bottom: 2px solid white;
    overflow-y: scroll;
    height: 575px;
    padding-inline-start: 0;

    &.fullHeight {
      height: calc(100% - 100px);
    }

    li {
      list-style: none;
    }

    .newMessages {
      border: 1px solid red;
    }

    .messageData {
      margin-bottom: 15px;
    }

    .messageDataTime {
      color: lighten($gray, 8%);
      padding-left: 6px;
    }

    .messageDataName {

    }

    .message {
      color: white;
      padding: 18px 20px;
      line-height: 26px;
      font-size: 16px;
      border-radius: 7px;
      margin-bottom: 30px;
      width: 90%;
      position: relative;
      white-space: pre-line;

      &:after {
        bottom: 100%;
        left: 7%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: $green;
        border-width: 10px;
        margin-left: -10px;
      }
    }

    .breadcrumbs {
      width: 90%;
      margin-top: -20px;
      margin-bottom: 20px;
      color: $gray !important;

      .breadcrumbLink {
        color: $gray !important;
      }

      .textRight {
        text-align: right;
      }
    }

    .myMessage {
      background: $blue;
      &:after {
        left: 93%;
      }
      &:after {
        border-bottom-color: $blue;
      }
    }

    .otherMessage {
      background: $green;

    }

  }

  .chatMessage {
    padding: 30px;

    textarea {
      width: 100%;
      border: none;
      padding: 10px 20px;
      margin-bottom: 10px;
      border-radius: 5px;
      resize: none;

    }

    button {
      float: right;
      color: $blue;
      font-size: 16px;
      text-transform: uppercase;
      border: none;
      cursor: pointer;
      font-weight: bold;
      background: #F2F5F8;

      &:hover {
        color: darken($blue, 7%);
      }
    }
  }

  .me {
    color: $blue;
  }

  .alignLeft {
    text-align: left;
  }

  .alignRight {
    text-align: right;
  }

  .floatRight {
    float: right;
  }

  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}
