@media screen and (max-width: 960px) {
  .container-property-list {
    .p-datatable .p-datatable-tbody > tr {
      display: block;
      padding: 1em 0;
    }
    .p-datatable .p-datatable-tbody > tr > td {
      padding: 0.5em 2em;
    }
  }
}
