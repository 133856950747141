.MuiButton-root.Button {
  border-radius: 0;
  padding: 1em;
  box-shadow: none;
  &.Button-default {
    background-color: #eee;
    &:hover {
      color: black;
      background-color: #ccc;
    }
  }

  &:hover {
    box-shadow: none;
    color: #ccc;
  }
}

.MuiButton-root.Button-Basic {
  border-radius: 0;
  text-transform: capitalize;
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }
}
