@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.btn-custom:focus {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  box-shadow: none;
}

.btn-custom:hover,
.btn-custom:active,
.btn-custom.active,
.open > .dropdown-toggle.btn-custom {
  color: #032340;
  background-color: #00b3db;
  border-color: #032340;
}

.prop-actions {
  & > div {
    display: inline-block;
  }

  & > * {
    margin-left: 10px;
  }

  display: flex;
  justify-content: flex-end;
}
