//Messaging Style
.messages-col {
  border-top-right-radius: 1%;
}
.messaging-count {
  padding-top: 25px;
}
.messaging-count .col:first-of-type {
  min-width: 200px;
}
.messaging-count li {
  list-style: none;
  padding: 11px 0px;
  font-family: 'RUBIK';
  font-size: 14px;
  line-height: 20px;
  color: #6a74a5;
}
.messaging-count-number {
  text-align: right;
  margin-right: 10%;
  color: #3a3f63;
  font-size: 20px !important;
  line-height: 20px;
  font-weight: 500;
  max-width: 75px;
}
.messaging-count-txt span {
  border-radius: 100%;
  margin: 5px;
  display: inline-block;
  width: 12px;
  height: 12px;
  vertical-align: bottom;
}
.messaging-count-txt,
.messaging-count-number {
  cursor: pointer;
}
.recharts-wrapper {
  margin: 35px 30px;
}

@media screen and (max-width: 999px) {

  div.messages-col {
    max-width: 100%;
    margin: 0 auto;
    padding-bottom: 20px;
  }

  .recharts-wrapper {
    margin: auto;
    margin-top: 21px;
  }
}

.schedule-weekly {

  .day {
    display: flex;
    flex:1;
    flex-direction: column;
    padding: 4px;

    & > * {
      width: 100%;
    }
  }

  .day-events {
    min-height: 40px;
    max-height: 175px;
    flex: 1 1 auto;
    overflow-y: auto;
  }

  .event-card {
    font-size: 10px;
    padding: 2px 4px;
  }
}
