.counter {
  position: absolute;
  top: 0;
  right: 0;
  font-style: normal;
  text-align: center;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 15px;
  color: #eee;
  border: 1px solid #444;
  background: #555;
  z-index: 1999;
}

.left {
  left: -40px;
  right: auto;
  background: #014a44;
  border: darken(#014a44, 0.4);
  color:  #eee;
}
