.custom-scrollbar {
    overflow: auto;
    scrollbar-width: thin;
    // IE styles
    scrollbar-face-color: #C0C0C0;
    scrollbar-arrow-color: #C0C0C0;
    scrollbar-track-color: transparent;
    scrollbar-shadow-color: white;
    scrollbar-highlight-color: white;
    scrollbar-3dlight-color: white;
    scrollbar-darkshadow-color: transparent;

    /* total width */
    &::-webkit-scrollbar {
        background-color: transparent;
        width: 8px;
        height: 8px;
    }

    /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track {
        background: transparent;
        background: rgba(#C0C0C0, 0.15);
    }

    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
        background-color: rgba(#C0C0C0, 0.5);
        border-radius: 0px;
        border: 1px solid white;
    }

    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
        display: none;
    }

    @media (hover:hover) {
        &:hover {
            /* scrollbar itself */
            &::-webkit-scrollbar-thumb {
                background-color: #C0C0C0;
            }
            scrollbar-color: #C0C0C0 rgba(#C0C0C0, 0.15);
        }
    }

    /* new W3C standards, currently Firefox only */
    scrollbar-width: thin;
    scrollbar-color: rgba(#C0C0C0, 0.5) rgba(#C0C0C0, 0.15);
    /* pre Chromium MS Edge */
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &.cz-scroll--no-scrollbar {
        &::-webkit-scrollbar {
            display: none;
        }

        scrollbar-width: none;
    }
}